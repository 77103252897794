<template>

      <!-- Public Information Form -->
      <j-form model="staff.contact">

        <!-- Header -->
        <v-col cols="12">
          <h3 class="headline">
            Public Information
          </h3>
          <p class="caption">
            This information is visible by everyone.
          </p>
        </v-col>

        <v-col cols="9">
          <v-row>

            <!-- First Name -->
            <j-control
              name="first_name"
              cols="12"
            />

            <!-- Last Name -->
            <j-control
              name="last_name"
              cols="12"
            />
          </v-row>
        </v-col>

        <!-- Badge -->
        <j-control
          name="badge_src"
          label="Profile Image"
          cols="3"
          style="max-height: 200px;"
        />

        <!-- Title -->
        <j-control
          v-if="$user.isAdmin"
          name="title"
          cols="9"
        />

        <!-- Position -->
        <j-control
          v-if="$user.isAdmin"
          name="position"
          cols="3"
        />

        <!-- Quote -->
        <j-control name="tagline" />

        <!-- Location -->
        <j-control
          name="location"
          cols="12"
          sm="6"
        />

        <!-- Website -->
        <j-control
          name="website"
          cols="12"
          sm="6"
        />

        <!-- Brief Bio -->
        <j-control name="brief_bio" />

        <!-- Full Bio -->
        <j-control
          name="full_bio"
          rows="10"
        />

      </j-form>

</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('staff')

export default {
  name: 'StaffPublicForm',
  // -----------
  // Computed ==
  // -----------
  computed: {
    ...mapState([
      'contact'
    ])
  }
}
</script>
